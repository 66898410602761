import React from 'react'
import BlockText from './block-text'
import classNames from 'classnames'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import { Fade } from 'react-awesome-reveal'

const ProjectContact = props => {
  const { projectContact, _rawProjectContact } = props
  const headline = _rawProjectContact && _rawProjectContact.projectContactHeadline
  const subline = _rawProjectContact && _rawProjectContact.projectContactSubline
  const teamMember = projectContact && projectContact.projectContactMember
  const formUrl = _rawProjectContact && _rawProjectContact.contactFormUrl
  const formButtonText = _rawProjectContact && _rawProjectContact.contactFormButtonText
  const imgUrl =
    teamMember &&
    teamMember.image &&
    imageUrlFor(buildImageObj(teamMember.image))
      .width(314)
      .height(380)
      .url()

  const classes = classNames('section contact project-contact', {
    'has-subline': subline,
    'has-form-btn': formUrl,
  })

  return (
    <section className={classes}>
      <div className="section__header is-lined-top">Kontakt</div>
      <div className="contact__inner">
        <div className="project-contact__headline is-size-1 is-size-2-mobile">
          {headline &&
            headline.map(textBlock => (
              <Fade duration={400} triggerOnce key={textBlock._key}>
                <BlockText blocks={textBlock} />
              </Fade>
            ))}
        </div>
        {subline && (
          <div className="project-contact__subline is-size-3 is-size-4-mobile">
            {subline.map(textBlock => (
              <Fade duration={400} triggerOnce key={textBlock._key}>
                <BlockText blocks={textBlock} />
              </Fade>
            ))}
          </div>
        )}
        <div className="project-contact__form-link">
          {formUrl && (
            <a className="btn" href={formUrl} target="_blank" rel="noopener noreferrer">
              {formButtonText ? formButtonText : 'Anfrage senden'}
            </a>
          )}
        </div>
        {teamMember && (
          <Fade duration={400} triggerOnce>
            <div className="project-contact__team-member">
              <div className="project-contact__bg-image-container">
                <div
                  className="project-contact__bg-image"
                  style={{ backgroundImage: `url(${imgUrl})` }}
                />
              </div>
              <div className="project-contact__details is-size-3 is-size-6-mobile">
                <div>
                  {teamMember.name} <br />
                  {teamMember.position}
                </div>
                {teamMember.phoneNumber && (
                  <div>
                    <a href={`tel:${teamMember.phoneNumber}`}>{teamMember.phoneNumber}</a>
                  </div>
                )}
                {teamMember.email && !formUrl && (
                  <div>
                    <a href={`mailto:${teamMember.email}`}>{teamMember.email}</a>
                  </div>
                )}
              </div>
            </div>
          </Fade>
        )}
      </div>
    </section>
  )
}

export default ProjectContact
