import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs, getNextNodeSlug } from '../lib/helpers'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Project from '../components/project'
import SEO from '../components/seo'
import Helmet from 'react-helmet'
import classNames from 'classnames'
import {
  isSafari,
  isChrome,
  isFirefox,
  isEdge,
  isMobile,
  isMobileSafari,
} from 'react-device-detect'

export const query = graphql`
  query ProjectTemplateQuery($id: String!) {
    project: sanityProject(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      tagline
      heroSliderImages {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
        alt
        limitHeroSliderSize
      }
      _rawBody
      projectType
      categories {
        title
      }
      status {
        title
      }
      area {
        title
      }
      location {
        title
      }
      customTableItems {
        title
        value
      }
      projectDownloads {
        title
        file {
          asset {
            url
          }
        }
      }
      projectContact {
        projectContactMember {
          name
          position
          phoneNumber
          email
          slug {
            current
          }
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
        }
      }
      _rawProjectContact
      publishedAt
      ...ProjectBuilder
    }
    projects: allSanityProject(
      sort: { fields: [title], order: ASC }
      filter: { slug: { current: { ne: null } }, visible: { eq: true } }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          area {
            title
          }
        }
      }
    }
  }
`

const ProjectTemplate = props => {
  const { data, errors } = props
  const project = data && data.project
  const projects = data && data.projects
  const prevPath = props.location.state && props.location.state.prevPath
  const htmlClasses = classNames({
    'is-safari': isSafari,
    'is-chrome': isChrome,
    'is-firefox': isFirefox,
    'is-edge': isEdge,
    'is-mobile': isMobile,
    'is-mobile-safari': isMobileSafari,
  })

  const slug = project.slug
  const projectNodes = mapEdgesToNodes(projects).filter(filterOutDocsWithoutSlugs)
  const [nextProjectSlug, setNextProjectSlug] = useState(getNextNodeSlug(slug, projectNodes))

  useEffect(() => {
    setNextProjectSlug(getNextNodeSlug(slug, projectNodes))
  }, [])

  return (
    <>
      <Helmet>
        <html className={htmlClasses} />
        <body className="is-project-template" />
      </Helmet>
      {/* {errors && <SEO title="GraphQL Error" />} */}
      {project && <SEO title={project.title || 'Untitled'} />}
      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {project && <Project {...project} prevPath={prevPath} nextProjectSlug={nextProjectSlug} />}
    </>
  )
}

export default ProjectTemplate
