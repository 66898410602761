import React from 'react'
import PropTypes from 'prop-types'
import GoogleMap from 'google-map-react'
import GoogleMapMarker from './googleMapMarker'
import { isMobile } from 'react-device-detect'

const BerlinBrandenburg = {
  lat: 52.4055498,
  lng: 13.0503168,
}

export default class ProjectsGoogleMap extends React.Component {
  constructor(props) {
    super(props)
  }

  static propTypes = {
    center: PropTypes.array,
    zoom: PropTypes.number,
    coords: PropTypes.any,
  }
  static defaultProps = {
    center: [BerlinBrandenburg.lat, BerlinBrandenburg.lng],
    zoom: 9,
    coords: BerlinBrandenburg,
  }

  render() {
    const { mapLocations, zoom, center } = this.props
    const mapHeight = isMobile ? '40vh' : '75vh'

    const createMapOptions = mapData => {
      return {
        panControl: true,
        mapTypeControl: false,
        scrollwheel: false,
        fullscreenControl: false,
        zoomControl: false,
        scaleControl: false,
        draggable: false,
        draggableCursor: 'default',
        clickableIcons: false,
        mapTypeControl: false,
        language: 'de',
        region: 'de',
        styles: [
          {
            // stylers: [{ saturation: -100 }, { gamma: 0.8 }, { lightness: 4 }, { visibility: 'on' }],
            stylers: [{ saturation: -100 }],
          },
        ],
      }
    }

    const fitMapToBounds = map => {
      const lats = mapLocations.map(item => item.location.lat)
      const lngs = mapLocations.map(item => item.location.lng)
      const latMax = Math.max(...lats)
      const latMin = Math.min(...lats)
      const lngMax = Math.max(...lngs)
      const lngMin = Math.min(...lngs)
      map.setCenter(new google.maps.LatLng((latMax + latMin) / 2.0, (lngMax + lngMin) / 2.0))
      map.fitBounds(
        new google.maps.LatLngBounds(
          new google.maps.LatLng(latMin, lngMin),
          new google.maps.LatLng(latMax, lngMax)
        )
      )
    }

    const apiIsLoaded = map => {
      fitMapToBounds(map)
    }

    return (
      <div className="projects-google-map">
        <div className="projects-google-map__map">
          <GoogleMap
            // apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}
            bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAPS_API_KEY }}
            center={center}
            zoom={zoom}
            style={{ height: mapHeight, position: 'relative' }}
            options={createMapOptions}
            onGoogleApiLoaded={({ map }) => apiIsLoaded(map)}
            yesIWantToUseGoogleMapApiInternals={true}
          >
            {mapLocations.map((item, i) => (
              <GoogleMapMarker
                lat={item.location.lat}
                lng={item.location.lng}
                text={`${i + 1}`}
                key={i}
              />
            ))}
          </GoogleMap>
        </div>
        <div className="projects-google-map__description is-size-7">
          {mapLocations.map((item, i) => (
            <span className="projects-google-map__description-item" key={i}>
              <span className="projects-google-map__index-dot">
                <span>{i + 1}</span>
              </span>
              <span className="projects-google-map__label">{item.name}</span>
            </span>
          ))}
        </div>
      </div>
    )
  }
}
