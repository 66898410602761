import React from 'react'
import TextImageSlider from '../components/textImageSlider'
import VisibilitySensor from 'react-visibility-sensor'

class ProjectBuilderTextImageSlider extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const raw = this.props.raw
    return (
      <VisibilitySensor
        onChange={isVisible => isVisible && this.props.updateCurrentSubsection(raw.title)}
        scrollCheck
        intervalCheck
        intervalDelay={50}
        partialVisibility
        minTopValue={300}
      >
        <TextImageSlider textImageSlider={raw} />
      </VisibilitySensor>
    )
  }
}

export default ProjectBuilderTextImageSlider
