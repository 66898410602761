import React from 'react'
import BlockText from './block-text'
import VisibilitySensor from 'react-visibility-sensor'
import { Fade } from 'react-awesome-reveal'
import ReactPlayer from 'react-player'

class ProjectBuilderVideo extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { title, videoUrl, controls, videoTitle } = this.props.raw
    const _controls = controls ? true : false
    return (
      <VisibilitySensor
        onChange={isVisible => isVisible && this.props.updateCurrentSubsection(title)}
        scrollCheck
        intervalCheck
        intervalDelay={50}
        partialVisibility
        minTopValue={300}
        offset={{ top: 100, bottom: 100 }}
      >
        <div className="project-builder__block video" id={`${title.toLowerCase()}`}>
          <Fade duration={400} triggerOnce>
            <div>
              <div className="video-container is-fullwidth">
                <ReactPlayer
                  url={videoUrl}
                  playing={!_controls}
                  loop={!_controls}
                  muted={!_controls}
                  controls={_controls}
                  width="100%"
                  height="100%"
                />
              </div>
              {title && <div className={`text-video__description is-size-7`}>{videoTitle}</div>}
            </div>
          </Fade>
        </div>
      </VisibilitySensor>
    )
  }
}

export default ProjectBuilderVideo
