import React from 'react'
import ProjectHero from '../components/projectHero'
import ProjectIntro from '../components/projectIntro'
import ProjectBuilder from '../components/projectBuilder'
import ProjectDownloads from '../components/projectDownloads'
import ProjectContact from '../components/projectContact'
import Sticky from 'react-stickynode'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import VisibilitySensor from 'react-visibility-sensor'
import classNames from 'classnames'
import { isBrowser } from '../lib/helpers'

class Project extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSubection: null,
      showSubsectionNav: true,
      nextProjectSlug: '',
      navUpdating: null,
    }
  }

  updateCurrentSubsection = subsection => {
    if (!this.state.navUpdating) {
      this.setState({ currentSubection: subsection })
    }
  }

  updateNav = title => {
    this.setState({ navUpdating: true })
    setTimeout(() => {
      this.setState({ currentSubection: title })
      this.setState({ navUpdating: false })
    }, 800)
  }

  render() {
    const {
      title,
      tagline,
      heroSliderImages,
      _rawBody,
      area,
      location,
      projectType,
      customTableItems,
      status,
      projectBuilder,
      _rawProjectBuilder,
      projectDownloads,
      projectContact,
      _rawProjectContact,
      prevPath,
      slug,
      nextProjectSlug,
    } = this.props

    const projectBuilderNavItems = _rawProjectBuilder && _rawProjectBuilder.map(item => item.title)

    return (
      <div className="project">
        <ProjectHero
          title={title}
          tagline={tagline}
          heroSliderImages={heroSliderImages}
          area={area}
          location={location}
          prevPath={prevPath}
          slug={slug}
          nextProjectSlug={nextProjectSlug}
        />
        <ProjectIntro
          introText={_rawBody}
          projectType={projectType}
          status={status}
          customTableItems={customTableItems}
        />
        {projectBuilder && projectBuilderNavItems && projectBuilder.length ? (
          <div className="section is-tight-mobile project-builder" id="project-builder">
            <div className="section__ruler" />
            <div className="project-builder__inner">
              <div
                className={classNames('project-builder__nav', {
                  'is-in': this.state.showSubsectionNav,
                })}
              >
                <Sticky enabled={true} top={84} bottomBoundary="#content" activeClass="is-sticky">
                  {projectBuilderNavItems.map((item, i) => {
                    if (item)
                      return (
                        <div
                          className={classNames('project-builder__nav-item', {
                            'is-in': item === this.state.currentSubection,
                          })}
                          key={i}
                        >
                          <AnchorLink
                            offset={i === 0 ? 84 : 49}
                            href={`#${item.toLowerCase()}`}
                            onClick={() => this.updateNav(item)}
                          >
                            {item}
                          </AnchorLink>
                        </div>
                      )
                  })}
                </Sticky>
              </div>
              <div className="project-builder__content">
                {projectBuilder && (
                  <ProjectBuilder
                    projectBuilder={projectBuilder}
                    _rawProjectBuilder={_rawProjectBuilder}
                    updateCurrentSubsection={this.updateCurrentSubsection}
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <VisibilitySensor
          onChange={isVisible => {
            this.setState({ showSubsectionNav: !isVisible })
          }}
          partialVisibility
          // offset={{ top: 200 }}
          intervalCheck
          minTopValue={500}
        >
          <div>
            {projectDownloads && projectDownloads.length > 0 && (
              <ProjectDownloads projectDownloads={projectDownloads} />
            )}
            {projectContact && projectContact.projectContactMember && (
              <ProjectContact
                projectContact={projectContact}
                _rawProjectContact={_rawProjectContact}
              />
            )}
          </div>
        </VisibilitySensor>
      </div>
    )
  }

  componentDidMount() {
    if (isBrowser()) {
      const { hash } = location
      if (!hash) return
      window.requestAnimationFrame(() => {
        const anchor = document.querySelector(hash)
        const offset = anchor.getBoundingClientRect().top + window.scrollY - 49
        window.scroll({ top: offset, left: 0 })
      })
    }
  }
}

export default Project
