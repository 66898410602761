import React from 'react'
import 'react-slideshow-image/dist/styles.css'
import { Link } from 'gatsby'
import { Fade } from 'react-slideshow-image'
import { buildImageObj, isBrowser, isRetina, getNextNodeSlug } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import EllipseFill from '../images/icons/ellipse-fill.svg'
import AllProjects from '../images/icons/all-projects.svg'
import AllProjectsMobile from '../images/icons/all-projects-mobile.svg'
import MouseTooltip from 'react-sticky-mouse-tooltip'
import LazyImg from '../components/lazyImg'
import _debounce from 'lodash.debounce'

class ProjectHero extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMouseTooltipVisible: false,
      tooltipArrowDirection: null,
      imgWidth: 2400,
    }
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.slideRef = React.createRef()
    this.back = this.back.bind(this)
    this.next = this.next.bind(this)
  }

  onResize = () => {
    const imgWidth = this.getImgWidth()
    this.setState({
      imgWidth: imgWidth,
    })
  }

  getImgWidth = () => {
    let imgWidth
    if (isBrowser()) {
      const windowWidth = window.innerWidth
      imgWidth = windowWidth
      if (isRetina()) {
        imgWidth = imgWidth * 2
      }
      return Math.floor(imgWidth)
    } else {
      return 2400
    }
  }

  updateTooltipArrow(e) {
    let wM = window.innerWidth / 2
    let w = e.clientX
    let pos
    switch (true) {
      case w <= wM:
        pos = 'left'
        break
      case w > wM:
        pos = 'right'
        break
      default:
        pos = undefined
    }
    this.setState({ tooltipArrowDirection: pos })
  }

  onMouseEnter(e) {
    this.updateTooltipArrow(e)
    this.setState({ isMouseTooltipVisible: true })
  }

  onMouseMove(e) {
    this.updateTooltipArrow(e)
  }

  onMouseLeave() {
    this.setState({ isMouseTooltipVisible: false })
  }

  onSliderClick = () => {
    const tooltipArrowDirection = this.state.tooltipArrowDirection
    if (tooltipArrowDirection === 'right') {
      this.slideRef.current.goNext()
    } else if (tooltipArrowDirection === 'left') {
      this.slideRef.current.goBack()
    } else {
      this.slideRef.current.goNext()
    }
  }

  back() {
    this.slideRef.current.goBack()
  }

  next() {
    this.slideRef.current.goNext()
  }

  render() {
    const { title, tagline, heroSliderImages, area, location, nextProjectSlug } = this.props
    const nextLinkTextDesktop = 'Nächstes Projekt'
    const nextLinkTextMobile = '→'
    const singleImage = heroSliderImages.length === 1
    const imgWidth = this.state.imgWidth

    return (
      <div className="project-hero hero is-fullheight">
        <div className="project-hero__title project-hero__title--mobile">
          <h1 className="is-size-4">
            <span className="info-dot">
              <EllipseFill />
              &nbsp;
            </span>
            <span>
              {title},
              <br />
              {`${location.title ? location.title : area.title}`}
            </span>
          </h1>
          <div className="project-hero__links">
            <div>
              <Link
                className="btn project-hero-link project-hero-link--all-projects"
                to="/projekte/"
              >
                <AllProjectsMobile />
              </Link>
              <Link
                className="btn project-hero-link project-hero-link--next-project"
                to={`/projekte/${nextProjectSlug ? nextProjectSlug : ''}`}
              >
                <span>{nextLinkTextMobile}</span>
              </Link>
            </div>
          </div>
        </div>
        <div className={`project-hero__slider ${singleImage ? 'is-single-image' : ''}`}>
          <div className="slide-container">
            <Fade ref={this.slideRef} autoplay={false} arrows={false} transitionDuration={400}>
              {heroSliderImages &&
                heroSliderImages.map((image, i) => {
                  const imgUrl =
                    image.asset &&
                    imageUrlFor(buildImageObj(image))
                      .width(imgWidth)
                      .height(Math.floor((9 / 16) * imgWidth))
                      .url()
                  const imgPlaceholderUrl =
                    image.asset &&
                    imageUrlFor(buildImageObj(image))
                      .width(imgWidth)
                      .height(Math.floor((9 / 16) * imgWidth))
                      .quality(20)
                      .blur(80)
                      .url()
                  return (
                    <div
                      className={`each-fade ${image.limitHeroSliderSize ? 'reduced-size' : ''}`}
                      key={i}
                    >
                      <div
                        className="bg-image-container"
                        onMouseEnter={this.onMouseEnter}
                        onMouseMove={this.onMouseMove}
                        onMouseLeave={this.onMouseLeave}
                        onClick={!singleImage && this.onSliderClick}
                      >
                        <LazyImg
                          src={imgUrl}
                          placeholder={imgPlaceholderUrl}
                          classes="project-hero__image"
                          alt={image.alt ? image.alt : ''}
                        />
                      </div>
                      {/* <img
                        src={imgUrl}
                        onMouseEnter={this.onMouseEnter}
                        onMouseMove={this.onMouseMove}
                        onMouseLeave={this.onMouseLeave}
                        onClick={!singleImage && this.onSliderClick}
                      /> */}
                      {!singleImage && (
                        <MouseTooltip visible={this.state.isMouseTooltipVisible}>
                          <span
                            className={`slider-mouse-tooltip is-${this.state.tooltipArrowDirection}`}
                          >
                            →
                          </span>
                        </MouseTooltip>
                      )}
                    </div>
                  )
                })}
            </Fade>
          </div>
        </div>
        <div className="project-hero__title project-hero__title--desktop">
          <h1 className="is-size-3">
            <span className="info-dot">
              <EllipseFill />
              &nbsp;
            </span>
            {`${title}, ${location.title ? location.title : area.title}`}{' '}
            {tagline && `- ${tagline}`}
            {/* {tagline && (
              <span className="project-hero__tagline">
                <span> – </span>
                <span>{tagline}</span>
              </span>
            )} */}
          </h1>
          <div className="project-hero__links">
            <div>
              <Link
                className="btn project-hero-link project-hero-link--all-projects"
                to="/projekte/"
              >
                <AllProjects />
              </Link>
              <Link
                className="btn project-hero-link project-hero-link--next-project"
                to={`/projekte/${nextProjectSlug ? nextProjectSlug : ''}`}
              >
                <span>{nextLinkTextDesktop}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
  componentDidMount() {
    window.addEventListener(
      'resize',
      _debounce(() => {
        this.onResize()
      }, 1000)
    )
  }
  componentWillUnmount() {
    window.removeEventListener(
      'resize',
      _debounce(() => {
        this.onResize()
      }, 1000)
    )
  }
}

export default ProjectHero
