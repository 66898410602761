import React from 'react'
import ProjectBuilderImageSlider from './projectBuilderImageSlider'
import ProjectBuilderTextImageSlider from './projectBuilderTextImageSlider'
import ProjectBuilderHeadlineText from './projectBuilderHeadlineText'
import ProjectBuilderTextMap from './projectBuilderTextMap'
import ProjectBuilderPlan from './projectBuilderPlan'
import ProjectBuilderTextVideo from './projectBuilderTextVideo'
import ProjectBuilderVideo from './projectBuilderVideo'

function ProjectBuilder(props) {
  const { type, projectBuilder, _rawProjectBuilder, updateCurrentSubsection } = props

  // Load the right component, based on the _type from Sanity
  const Components = {
    projectBuilderImageSlider: ProjectBuilderImageSlider,
    projectBuilderTextImageSlider: ProjectBuilderTextImageSlider,
    projectBuilderHeadlineText: ProjectBuilderHeadlineText,
    projectBuilderTextMap: ProjectBuilderTextMap,
    projectBuilderPlan: ProjectBuilderPlan,
    projectBuilderTextVideo: ProjectBuilderTextVideo,
    projectBuilderVideo: ProjectBuilderVideo,
  }

  // 'raw' content needs to be passed in for the PortableText Component
  return projectBuilder.map((block, index) => {
    if (Components[block._type]) {
      return React.createElement(Components[block._type], {
        key: block._key,
        block: block,
        type,
        updateCurrentSubsection,
        raw: _rawProjectBuilder[index],
      })
    }
  })
}

export default ProjectBuilder
