import React from 'react'
import BlockText from './block-text'
import VisibilitySensor from 'react-visibility-sensor'
import { Fade } from 'react-awesome-reveal'
import ReactPlayer from 'react-player'

class ProjectBuilderTextVideo extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { title, text, videoUrl, controls, videoTitle } = this.props.raw
    const _controls = controls ? true : false
    return (
      <VisibilitySensor
        onChange={isVisible => isVisible && this.props.updateCurrentSubsection(title)}
        scrollCheck
        intervalCheck
        intervalDelay={50}
        // partialVisibility
        minTopValue={300}
        // offset={{ top: 100, bottom: 100 }}
      >
        <div
          className="project-builder__block text-video columns is-marginless"
          id={`${title.toLowerCase()}`}
        >
          <div className="text-video__text column is-flex">
            <div className="text-video__mobile-nav-title is-size-7">{title}</div>
            <div className="text-video__text-body is-size-5 is-size-6-mobile">
              {text.map((textBlock, i) => (
                <Fade duration={400} triggerOnce key={i}>
                  <BlockText blocks={textBlock} />
                </Fade>
              ))}
            </div>
          </div>
          <div className="text-video__video column">
            <Fade duration={400} triggerOnce>
              <div className="video-container">
                <ReactPlayer
                  url={videoUrl}
                  playing={!_controls}
                  loop={!_controls}
                  muted={!_controls}
                  controls={_controls}
                  width="100%"
                  height="100%"
                />
              </div>
              {title && <div className={`text-video__description is-size-7`}>{videoTitle}</div>}
            </Fade>
          </div>
        </div>
      </VisibilitySensor>
    )
  }
}

export default ProjectBuilderTextVideo
