import React from 'react'
import ImageSlider from '../components/imageSlider'
import VisibilitySensor from 'react-visibility-sensor'
import { Fade } from 'react-awesome-reveal'

class ProjectBuilderImageSlider extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { title, sliderImages } = this.props.raw
    return (
      <VisibilitySensor
        onChange={isVisible => isVisible && this.props.updateCurrentSubsection(title)}
        scrollCheck
        intervalCheck
        intervalDelay={50}
        partialVisibility
        minTopValue={300}
      >
        <div className="project-builder__block project-image-slider" id={`${title.toLowerCase()}`}>
          <Fade duration={400} triggerOnce>
            <ImageSlider images={sliderImages} isFullWidthSlider={true} />
          </Fade>
        </div>
      </VisibilitySensor>
    )
  }
}

export default ProjectBuilderImageSlider
