export default {
  label: 'Test Plan',
  viewBox: '0 0 1118 352',
  locations: [
    {
      name: 'A',
      id: '1',
      path:
        'M1084.5 252.858L1084.8 253.358M1084.8 253.358L1105.5 287.858H77L35.5 212.358L62 185.858L122 253.358H1084.8ZM1035 234.358H1076L1049 188.358H1035V234.358ZM130.5 225.358H177L344.5 68.8584L340 55.3584L326.5 39.8584L160.5 195.858H142.5L130.5 208.858V225.358Z',
    },
    {
      name: 'B',
      id: '2',
      path:
        'M552.5 126.358L561.5 92.3584L390.5 41.3584L383 21.8584L375 19.8584L355.5 27.8584L367 60.3584L382 73.8584L552.5 126.358Z',
    },
  ],
}
