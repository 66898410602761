import React from 'react'

const ProjectDownloads = props => {
  const downloads = props.projectDownloads

  return (
    <section className="section project-downloads is-tight-mobile">
      <div className="section__header is-lined-top">Downloads</div>
      <div className="project-downloads__inner">
        {downloads.map((item, i) => (
          <div key={i}>
            <a
              className="btn"
              href={item.file.asset.url}
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              ↓ {item.title}
            </a>
          </div>
        ))}
      </div>
    </section>
  )
}

export default ProjectDownloads
