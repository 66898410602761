import React from 'react'
import TestPlan from '../images/react-svg-map/plans/test-plan'
import NeueBergsiedlung2Bauabschnitt from '../images/react-svg-map/plans/neue-bergsiedlung-2-bauabschnitt'
import { SVGMap } from 'react-svg-map'
import { getLocationId, getLocationName } from '../lib/react-svg-map-utils'
import 'react-svg-map/lib/index.css'
import BlockText from './block-text'
import { Link } from 'gatsby'
import { navigate } from 'gatsby'
import LinksDownloads from './linksDowloads'
import VisibilitySensor from 'react-visibility-sensor'
import MouseTooltip from 'react-sticky-mouse-tooltip'

class ProjectBuilderPlan extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pointedPath: null,
      focusedPath: null,
      clickedPath: null,
      sectionTitle: null,
      sectionInfo: null,
      sectionLinks: null,
      isMouseOverImage: false,
      isMouseTooltipVisible: false,
      isMouseTooltipFaded: true,
    }
    this.handlePathMouseOver = this.handlePathMouseOver.bind(this)
    this.handlePathMouseOut = this.handlePathMouseOut.bind(this)
    this.handlePathClick = this.handlePathClick.bind(this)
    this.onMouseEnter = this.onMouseEnter.bind(this)
    // this.onMouseOver = this.onMouseOver.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
  }

  initPlanLocations = () => {
    const els = document.getElementsByClassName('svg-map__path')
    Array.from(els).forEach(el => {
      const name = el.hasAttribute('name') && el.getAttribute('name')
      if (name && name.includes('type--streets')) {
        el.classList.add('svg-map__path--streets')
      }
      if (name && name.includes('outline-only')) {
        el.classList.add('svg-map__path--outline')
      }
      if (name && name.includes('type--parking')) {
        el.classList.add('svg-map__path--parking')
      }
      if (name && name.includes('type--unknown')) {
        el.classList.add('svg-map__path--unknown')
      }
      if (name && name.includes('type--dotted-lines')) {
        el.classList.add('svg-map__path--dotted-lines')
      }
      if (name && name.includes('type--dotted-lines')) {
        el.classList.add('svg-map__path--dotted-lines')
      }
      if (name && name.includes('type--park')) {
        el.classList.add('svg-map__path--park')
      }
      if (name && name.includes('type--trees')) {
        el.classList.add('svg-map__path--trees')
      }
      if (name && name.includes('type--property-lines')) {
        el.classList.add('svg-map__path--property-lines')
      }
      if (name && name.includes('type--properties')) {
        el.classList.add('svg-map__path--properties')
      }
      if (name && name.includes('no-underlay-fill')) {
        el.classList.add('svg-map__path--no-underlay-fill')
      }
      if (name && name.includes('effect--shadowed')) {
        el.classList.add('svg-map__path--shadowed')
      }
    })
  }

  updateSectionInfo = id => {
    const { sections } = this.props.raw
    const section = sections.find(obj => {
      return obj.sectionRef === id
    })
    if (section) {
      this.setState({
        sectionTitle: section.title,
        sectionInfo: section.customTableItems,
        sectionLinksDownloads: section.linksDownloads,
        isMouseTooltipFaded: false,
      })
    }
  }

  // SVG Map Data
  handlePathMouseOver = e => {
    const pointedPathId = getLocationId(e)
    pointedPathId !== null && this.updateSectionInfo(pointedPathId)
    // if (pointedPathId !== null) {
    //   this.updateSectionInfo(pointedPathId)
    //   this.setState({
    //     isMouseTooltipFaded: false,
    //   })
    // } else {
    //   this.setState({
    //     isMouseTooltipFaded: true,
    //   })
    // }
  }

  handlePathMouseOut = e => {
    this.setState({ pointedPath: null })
  }

  handlePathClick = e => {
    const clickedPathId = getLocationId(e)
    clickedPathId !== null && this.updateSectionInfo(clickedPathId)
  }

  // Mouseover Tooltip
  onMouseEnter(e) {
    this.setState({ isMouseOverImage: true })
  }

  onMouseLeave() {
    this.setState({ isMouseOverImage: false })
  }

  getPlanFile(name) {
    let planFile = null
    switch (name) {
      case 'test-plan':
        planFile = TestPlan
        break
      case 'neue-bergsiedlung-2-bauabschnitt':
        planFile = NeueBergsiedlung2Bauabschnitt
        break
      default:
        planFile = null
    }
    return planFile
  }

  render() {
    const { title, text, linkText, internalLink, name } = this.props.raw
    const planFile = name && this.getPlanFile(name)
    return (
      <VisibilitySensor
        onChange={isVisible => isVisible && this.props.updateCurrentSubsection(title)}
        scrollCheck
        intervalCheck
        intervalDelay={50}
        partialVisibility
        minTopValue={300}
      >
        <section
          className={`project-builder__block project-plan
        ${this.state.isMouseTooltipFaded}
        `}
          id={`${title.toLowerCase()}`}
        >
          <div className="column is-full project-plan__plan">
            <div>
              <div className="project-plan__hover-info">
                <span>
                  {this.state.sectionTitle && (
                    <div className="project-plan__info-group">{this.state.sectionTitle}</div>
                  )}
                  {this.state.sectionInfo &&
                    this.state.sectionInfo.map(item => {
                      return (
                        <div>
                          {item.title}: {item.value}
                        </div>
                      )
                    })}
                  {this.state.sectionLinksDownloads && (
                    <div className="project-plan__info-group">
                      <LinksDownloads linksDownloads={this.state.sectionLinksDownloads} />
                    </div>
                  )}
                </span>
              </div>
              {planFile && (
                <div
                  className="project-plan__svg"
                  onMouseEnter={this.onMouseEnter}
                  onMouseLeave={this.onMouseLeave}
                >
                  <SVGMap
                    map={NeueBergsiedlung2Bauabschnitt}
                    type="link"
                    locationClassName="svg-map__path is-link"
                    onLocationMouseOver={this.handlePathMouseOver}
                    onLocationMouseOut={this.handlePathMouseOut}
                    onLocationClick={this.handlePathClick}
                  />
                  {/* <MouseTooltip
                    visible={this.state.isMouseOverImage}
                    // visiible
                  >
                    <div className={`project-plan__tooltip`}>
                      <div className={`project-plan__tooltip-info`}>
                        <span>
                          {this.state.sectionTitle && (
                            <div className="project-plan__info-group">
                              {this.state.sectionTitle}
                            </div>
                          )}
                          {this.state.sectionInfo &&
                            this.state.sectionInfo.map(item => {
                              return (
                                <div>
                                  {item.title}: {item.value}
                                </div>
                              )
                            })}
                          {this.state.sectionLinksDownloads && (
                            <div className="project-plan__info-group">
                              <LinksDownloads linksDownloads={this.state.sectionLinksDownloads} />
                            </div>
                          )}
                        </span>
                      </div>
                    </div>
                  </MouseTooltip> */}
                </div>
              )}
            </div>
          </div>
        </section>
      </VisibilitySensor>
    )
  }
  componentDidMount() {
    this.initPlanLocations()
  }
}

export default ProjectBuilderPlan
