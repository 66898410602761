import React from 'react'
import 'react-slideshow-image/dist/styles.css'
import BlockContent from './block-content'

class ProjectIntro extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { introText, projectType, status, customTableItems } = this.props

    return (
      <div className="project-intro">
        <div className="project-intro__text is-size-3 is-size-6-mobile">
          {introText && <BlockContent blocks={introText || []} />}
        </div>
        <div className="project-intro__table is-size-7">
          <div className="project-intro__table-row">
            <div>Projektart:</div>
            <div>{projectType}</div>
          </div>
          {customTableItems &&
            customTableItems.map((item, i) => (
              <div className="project-intro__table-row" key={i}>
                <div>{item.title}:</div>
                <div>{item.value}</div>
              </div>
            ))}
          <div className="project-intro__table-row">
            <div>Status:</div>
            <div>{status.title}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default ProjectIntro
