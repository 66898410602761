import React from 'react'
import { Fade } from 'react-awesome-reveal'
import BlockText from './block-text'

const ProjectBuilderHeadlineText = ({ block, raw, index }) => {
  const { title, content } = raw

  return (
    <div className="project-builder__headline-text is-lined-top">
      <Fade duration={400} triggerOnce>
        {content && content.map(textBlock => <BlockText blocks={textBlock} key={textBlock.id} />)}
      </Fade>
    </div>
  )
}

export default ProjectBuilderHeadlineText
