export default {
  label: 'neue-bergsiedlung-2-bauabschnitt',
  viewBox: '0 0 681 463',
  locations: [
    // STREETS
    {
      name: 'type--streets',
      path:
        'M74.5 168L99 54L177.5 33.5L208 18L242.5 9L250.5 0.5H222L33.5 51.5L35 58.5L47 63L58 70L1.5 330.5L37.5 338L68 197.5L657 325L669.5 370.5L672 379L680 376.5L599 65L493.792 92.8083C488.703 94.1533 483.291 92.5306 479.781 88.6082L477 85.5L484 110.5C487.557 105.649 492.679 102.174 498.502 100.661L577.08 80.2465C585.059 78.1733 593.217 82.9291 595.344 90.8948L642.5 267.5L592.5 280.5L293 215.454L268.219 124.914C265.888 116.398 270.896 107.604 279.409 105.263L284 104L281.5 95.5L234 108.5L236.5 117L240.164 116.036C248.656 113.801 257.362 118.828 259.672 127.299L267.5 156L277.673 194.25C279.128 199.72 277.765 205.555 274.038 209.814L273 211L212 197.5L74.5 168Z',
    },
    {
      name: 'type--streets outline-only',
      path: 'M608 461.5L37.5 338L68 197.5L657 325L672 379L623 393.5L608.5 459L608 461.5Z',
    },
    {
      name: 'type--streets outline-only',
      path: 'M125 127L141.5 122.5L150.067 156.5L157.5 186L74.5 168L86.5 112L119 104L125 127Z',
    },
    // {
    //   name: 'type--streets',
    //   path:
    //     'M157.5 186L150.067 156.5M86.5 112L119 104L125 127L141.5 122.5L150.067 156.5M198 144L150.067 156.5',
    // },
    // PARKING
    {
      name: 'type--parking',
      path:
        'M657 325L672 379L680 376.5L599 65L493.792 92.8083C488.703 94.1533 483.291 92.5306 479.781 88.6082L477 85.5L484 110.5C483.571 109 491.5 102.5 498.502 100.661L577.08 80.2465C585.059 78.1733 593.217 82.9291 595.344 90.8948L642.5 267.5L592.5 280.5L513.75 263.397L522.25 295.831L657 325Z',
    },
    {
      name: 'type--parking',
      path: 'M211 141L92 172L228 201.5L211 141Z',
    },
    // LINES (DOTTED)
    {
      name: 'type--dotted-lines',
      path:
        'M226.848 194.887L212.118 142.352L252.148 131.612L266.387 184.148L226.848 194.887ZM68.5971 318.117L92.8806 208.593L275.903 247.883L251.619 357.407L68.5971 318.117ZM335.122 374.905L299.597 367.119L323.879 257.6L359.902 265.876L335.122 374.905ZM383.597 386.118L407.88 276.595L555.407 308.882L536.861 395.695L509.9 413.467L383.597 386.118ZM624.617 381.403L560.09 367.118L571.387 312.597L636.406 326.881L624.617 381.403ZM545.46 96.6481L586.646 85.6123L634.539 264.352L593.354 275.388L545.46 96.6481Z',
    },
    // TREES
    {
      name: 'type--trees',
      path:
        'M591.579 443.532C591.843 446.003 594.061 447.792 596.532 447.528C599.003 447.264 600.792 445.046 600.528 442.575C600.264 440.104 598.046 438.315 595.575 438.579C593.104 438.843 591.315 441.061 591.579 443.532ZM590.585 443.638C590.908 446.659 593.618 448.845 596.638 448.522C599.659 448.199 601.845 445.489 601.522 442.469C601.2 439.448 598.489 437.262 595.469 437.585C592.449 437.908 590.262 440.618 590.585 443.638Z',
    },
    {
      name: 'type--trees',
      path:
        'M549.191 429.918C550.433 434.445 555.11 437.108 559.637 435.866C564.164 434.624 566.827 429.947 565.585 425.42C564.343 420.893 559.666 418.229 555.139 419.472C550.612 420.714 547.949 425.39 549.191 429.918ZM548.227 430.182C549.615 435.242 554.842 438.218 559.902 436.83C564.961 435.442 567.938 430.215 566.55 425.155C565.161 420.095 559.934 417.119 554.875 418.507C549.815 419.895 546.839 425.122 548.227 430.182Z',
    },
    {
      name: 'type--trees',
      path:
        'M605.786 414.98C609.89 412.702 611.371 407.527 609.093 403.423C606.814 399.318 601.64 397.838 597.535 400.116C593.431 402.394 591.95 407.569 594.229 411.673C596.507 415.778 601.681 417.258 605.786 414.98ZM606.271 415.854C610.858 413.308 612.513 407.525 609.967 402.938C607.421 398.35 601.638 396.695 597.05 399.242C592.463 401.788 590.808 407.571 593.354 412.158C595.901 416.746 601.684 418.401 606.271 415.854Z',
    },
    {
      name: 'type--trees',
      path:
        'M584.428 425.988C586.601 424.782 587.384 422.043 586.178 419.87C584.972 417.697 582.233 416.913 580.06 418.119C577.887 419.325 577.103 422.065 578.309 424.238C579.515 426.411 582.255 427.194 584.428 425.988ZM584.913 426.863C587.569 425.388 588.527 422.04 587.053 419.385C585.578 416.729 582.23 415.771 579.575 417.245C576.919 418.719 575.961 422.067 577.435 424.723C578.909 427.379 582.257 428.337 584.913 426.863Z',
    },
    // PARK
    {
      name: 'type--park',
      path: 'M500.5 435.5L478.5 425.5L669.5 370.5L672 379L623 393.5L608.5 459L500.5 435.5Z',
    },
    {
      name: 'type--park',
      path: 'M500.5 435.5L478.5 425.5L669.5 370.5L672 379L623 393.5L608.5 459L500.5 435.5Z',
    },
    // PROPERTY LINES
    {
      name: 'type--property-lines',
      path:
        'M104.5 336.5L46 324.5V308.5C46 305.3 50.3333 281.5 52.5 270L55 257.5L69.5 189.5L109.167 198.077C117.88 199.96 123.373 208.601 121.382 217.289L119.5 225.5L121 226.5L111 270L104.5 304L111 306L118.5 307.5L121.559 292.884C122.354 289.088 126.084 286.661 129.877 287.474L151 292L155 273L131.822 268.228C125.853 266.999 122.021 261.151 123.276 255.188L129 228H130.5L132.714 218.338C134.539 210.375 142.402 205.336 150.4 207.005L193.5 216L233.167 224.577C241.88 226.46 247.373 235.101 245.382 243.789L243.5 252H244.5L235 296.5L227.5 331L235 333L242 334.5L245.468 319.242C246.317 315.505 250.013 313.146 253.76 313.949L275 318.5L279 300L255.804 294.883C249.847 293.569 246.097 287.659 247.446 281.71L253.5 255H255.5L257.457 245.413C259.13 237.212 267.191 231.967 275.367 233.76L317.5 243L358.183 251.796C366.341 253.56 371.49 261.643 369.64 269.782L368 277L369.5 277.5C369.5 277.5 363.253 304.587 359.25 323C356.419 336.024 352 358 352 358L359.25 359.5L366.5 361L369.941 345.858C370.801 342.075 374.572 339.713 378.35 340.59L399.5 345.5L403.5 326.5L370 319.5L378 280.5H380L382.019 271.211C383.664 263.644 391.141 258.849 398.704 260.511L440.75 269.75L482.124 278.841C490.107 280.595 495.216 288.418 493.613 296.433L492 304.5L493 305.5L483.25 351.096L476 385L482.5 386.5L490.5 388L493.388 373.558C494.271 369.143 498.622 366.326 503.012 367.327L523.5 372L527.5 353.5L504.728 348.742C498.797 347.502 494.985 341.7 496.202 335.764L502 307.5H503.5L504.69 300.507C506.221 291.514 514.949 285.619 523.862 287.557L565 296.5L551.347 358.5L549 370L551.5 371L535.5 443L500.5 435.5L474.5 423.5L411 410.008L350.5 397L287.5 383L226.5 370.5L184.5 361L163.5 354L104.5 336.5Z',
    },
    // PROPERTIES (SELECTABLE)
    {
      name: 'type--properties',
      id: '19',
      path:
        'M46 324.5L104.5 336.5L111 306L104.5 304L111 270L55 257.5L52.5 269.5C50.3333 281 46 304.8 46 308V324.5Z',
    },
    {
      name: 'type--properties',
      id: '18',
      path:
        'M111 270L55 257.5L69.5 189.5L109.167 198.077C117.88 199.96 123.373 208.601 121.382 217.289L119.5 225.5L121 226.5L111 270Z',
    },
    {
      name: 'type--properties',
      id: '17',
      path:
        'M104.5 336.5L111 306L118.5 307.5L121.559 292.884C122.354 289.088 126.084 286.661 129.877 287.474L151 292L155 273L180 278.5L179 284L163.5 354L104.5 336.5Z',
    },
    {
      name: 'type--properties',
      id: '16',
      path:
        'M121 266L155 273L180 278.5L193.5 216L150.4 207.005C142.402 205.336 134.539 210.375 132.714 218.338L130.5 228H129L121 266Z',
    },
    {
      name: 'type--properties',
      id: '15',
      path:
        'M245 235.5L252.5 237.5L224.5 370.5L184.5 361L163.5 354L179 284L235 296.5L244.5 252H243.5L245.503 243.654C246.153 240.947 245.978 238.107 245 235.5Z',
    },
    {
      name: 'type--properties',
      id: '14',
      path:
        'M180 278.5L193.5 216L233.167 224.577C241.88 226.46 247.373 235.101 245.382 243.789L243.5 252H244.5L235 296.5L179 284L180 278.5Z',
    },
    {
      name: 'type--properties',
      id: '13',
      path:
        'M224.5 370.5L287.5 383L304 305.5L255.807 294.881C249.849 293.568 246.098 287.657 247.446 281.707L253.5 255H255.5L257.263 245.835C257.746 243.319 258.86 240.968 260.5 239L252.5 237.5L224.5 370.5Z',
    },
    {
      name: 'type--properties',
      id: '12',
      path:
        'M255.804 294.883L279 300L304 305.5L317.5 243L275.367 233.76C267.191 231.967 259.13 237.212 257.457 245.413L255.5 255H253.5L247.446 281.71C246.097 287.659 249.847 293.569 255.804 294.883Z',
    },
    {
      name: 'type--properties',
      id: '11',
      path:
        'M370 263L377 264.5L348.5 396.5L287.5 383L303 311L359 323L369.5 277.5L368 277L369.826 268.966C370.271 267.007 370.33 264.981 370 263Z',
    },
    {
      name: 'type--properties',
      id: '10',
      path:
        'M304.171 305.5L317.671 243L358.354 251.796C366.512 253.56 371.661 261.643 369.811 269.782L368.171 277L369.671 277.5C369.671 277.5 363.003 304.587 359 323L303 311L304.171 305.5Z',
    },
    {
      name: 'type--properties',
      id: '9',
      path:
        'M348.5 396.5L377 264.5L384 266C383.007 267.655 382.299 269.465 381.905 271.354L380 280.5H378L370 319.5L428 332L411 410L348.5 396.5Z',
    },
    {
      name: 'type--properties',
      id: '8',
      path:
        'M370 319.5L403.5 326.5L428 332L441 270L398.74 260.567C391.164 258.876 383.658 263.671 382.009 271.257L380 280.5H378L370 319.5Z',
    },
    {
      name: 'type--properties',
      id: '7',
      path:
        'M493.5 289.5L501 291L473 423.5L411 410L426.5 339L483 351L493 305.5L492 304.5L493.727 296.557C494.234 294.222 494.156 291.798 493.5 289.5Z',
    },
    {
      name: 'type--properties',
      id: '6',
      path:
        'M492 304.5L493.604 296.482C495.21 288.448 490.073 280.61 482.065 278.879L441 270L428 332L426.5 339L483 351L493 305.5L492 304.5Z',
    },
    {
      name: 'type--properties',
      id: '5',
      path:
        'M473 423.5L501 291L508.5 292.5C506.536 294.791 505.227 297.569 504.71 300.542L503.5 307.5H502L496.203 335.761C494.985 341.698 498.799 347.501 504.731 348.74L551.5 358.5L549 370L551.5 371L535.5 443L500.5 435.5L473 423.5Z',
    },
    {
      name: 'type--properties',
      id: '4',
      path:
        'M504.728 348.742L527.5 353.5L551.347 358.5L565 296.5L523.862 287.557C514.949 285.619 506.221 291.514 504.69 300.507L503.5 307.5H502L496.202 335.764C494.985 341.7 498.797 347.502 504.728 348.742Z',
    },
    {
      name: 'type--properties no-underlay-fill',
      id: '3',
      path: 'M625.5 203.5L570 218.5L586 279L592.5 280.5L642.5 267.5L625.5 203.5Z',
    },
    {
      name: 'type--properties no-underlay-fill',
      id: '2',
      path:
        'M273 211L273.613 210.343C277.59 206.083 279.107 200.082 277.633 194.444L267.583 156L205.5 172.661L212 197.5L273 211Z',
    },
    {
      name: 'type--properties no-underlay-fill',
      id: '1',
      path:
        'M213.5 140L198 144L205.5 172.661L267.583 156L259.726 127.388C257.39 118.883 248.612 113.872 240.101 116.184L209.5 124.5L213.5 140Z',
    },
    // UNKNOWN SHAPES
    {
      name: 'type--unknown',
      path:
        'M604.895 352.489L629.902 357.882L624.621 381.405L571.094 369.619L582.883 315.096L611.402 321.38L604.512 351.89L604.401 352.382L604.895 352.489ZM340.382 261.596L359.898 265.88L354.629 288.392L335.592 283.633L340.382 261.596ZM329.776 215.412L306.6 210.061L311.324 189.6L334.5 194.95L329.776 215.412ZM273.597 276.618L279.38 250.594L301.403 255.382L295.62 281.406L273.597 276.618ZM125.717 313.334L129.535 295.374L148.407 299.385L144.589 317.346L125.717 313.334',
    },
  ],
}
