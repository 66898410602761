import React from 'react'
import PropTypes from 'prop-types'

const K_WIDTH = 18
const K_HEIGHT = 18

const style = {
  position: 'absolute',
  width: K_WIDTH,
  height: K_HEIGHT,
  left: -K_WIDTH / 2,
  top: -K_HEIGHT / 2,
  borderRadius: K_HEIGHT,
  // backgroundColor: '#001A45',
  backgroundColor: 'rgb(5, 9, 14)',
  textAlign: 'center',
  color: '#fff',
  fontSize: 10,
  lineHeight: 1,
  fontWeight: 'normal',
  letterSpacing: '0.02em',
  padding: 4,
}

export default class GoogleMapMarker extends React.Component {
  static propTypes = {
    text: PropTypes.string,
  }

  static defaultProps = {}

  render() {
    return (
      <div className="projects-google-map__marker" style={style}>
        <span>{this.props.text}</span>
      </div>
    )
  }
}
